import type { HandleClientError } from '@sveltejs/kit'
import { PUBLIC_SENTRY_ENV, PUBLIC_SENTRY_DSN } from '$env/static/public'

/* Sentry */
import { handleErrorWithSentry, init as SentryInit } from "@sentry/sveltekit";
import { SentryEnvs } from '$lib/reporting/sentry/tools';


if (PUBLIC_SENTRY_ENV && SentryEnvs.includes(PUBLIC_SENTRY_ENV)) {
	SentryInit({
		dsn: PUBLIC_SENTRY_DSN,
		environment: PUBLIC_SENTRY_ENV,
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		integrations: []
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError:HandleClientError = handleErrorWithSentry();
