import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/(contentful)/(main)": [4,[2,3]],
		"/(contentful)/(main)/about-us": [5,[2,3]],
		"/(contentful)/(main)/about-us/awards": [6,[2,3]],
		"/(contentful)/(main)/about-us/guarantee": [7,[2,3]],
		"/(contentful)/(main)/about-us/vendors": [8,[2,3]],
		"/(contentful)/(main)/areas-we-serve": [9,[2,3]],
		"/(contentful)/(main)/areas-we-serve/[[state]]/[area]": [10,[2,3]],
		"/(contentful)/(no-layout)/coupon/[code]": [17,[2]],
		"/(contentful)/(main)/privacy-policy": [11,[2,3]],
		"/(contentful)/(main)/schedule": [~12,[2,3]],
		"/(tests)/sentry-example": [22],
		"/(contentful)/(main)/services": [13,[2,3]],
		"/(contentful)/(main)/services/[service]": [14,[2,3]],
		"/(contentful)/(main)/tips/safety": [15,[2,3]],
		"/(contentful)/(main)/tips/safety/[article]": [16,[2,3]],
		"/(tests)/[page=test]/dates": [18],
		"/(tests)/[page=test]/form": [~19],
		"/(tests)/[page=test]/schemas": [20],
		"/(tests)/[page=test]/urls": [21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';